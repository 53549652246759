<template>
  <main class="dashboard">
    <!-- <p class="title">{{ shopName }}: {{ contractPlan }}</p> -->
    <p class="title">店家操作紀錄數據分析</p>

    <FiltersContainer>
      <BaseElSelect
        v-model="actionFilter"
        multiple
        collapse-tags
        placeholder="請選擇"
        @change="handleActionFilterChange"
      >
        <BaseElSelectOption
          v-for="action in actionOptions"
          :key="action.key"
          :label="action.label"
          :value="action.key"
        />
      </BaseElSelect>

      <el-date-picker
        v-model="dateRange"
        editable
        type="daterange"
        format="yyyy-MM-dd"
        range-separator="至"
        start-placeholder="開始日期"
        end-placeholder="結束日期"
        @change="changeTimeRange"
      />
    </FiltersContainer>

    <div v-loading="reload" class="big-result-container">
      <div v-for="data in chartData.datasets" v-show="actionFilter.includes(data.key) || data.key === 'userActionLog'" :key="data.key" class="result">
        <span class="result-title">{{ data.label }}</span>
        <div>
          <span class="result-number">{{ data.total || '0' }}</span>
        </div>
      </div>

      <el-divider direction="vertical" class="h-full" />
    </div>

    <section ref="container-1" class="section-container" @resize="containerWidth()">
      <!-- v-if="!reload && chartData.datasets[0].data.length && !chartRendering" -->
      <div
        v-if="!reload && !chartRendering"
        style="width: 100%;"
        class="chart-wrapper"
      >
        <LineChart
          style="padding-top: 5px"
          :width="containerWidth()"
          :height="400"
          :data="chartData"
          :options="chartOptions"
        />
      </div>

      <!-- <EmptyBlock v-if="!chartData.datasets[0].data.length" /> -->
    </section>
  </main>
</template>

<script>
import EmptyBlock from '@/components/EmptyBlock.vue'
import LineChart from '@/components/LineChart.js'
import { GetPageActionRecord, GetUserActionLog } from '@/api/bob'
import { GetShopById } from '@/api/shop'
import { mapGetters } from 'vuex'
import { map, sum, find, findIndex } from 'lodash'
import dayjs from '@/lib/dayjs'

export default {
  name: 'Dashboard',
  components: { LineChart },

  data: () => ({
    reload: false,
    chartRendering: false,
    contractPlan: '',
    actionFilter: [],
    dateRange: [
      dayjs().subtract(3, 'month').startOf('day').toDate(),
      dayjs().endOf('day').toDate(),
    ],
    chartData: {
      labels: [],
      datasets: [

        {
          label: '組織頁面',
          data: [],
          key: 'orgPage',
          fill: false,
          hidden: true,
          tension: 0.3,
        },
        {
          label: '基本模組',
          data: [],
          key: 'shopBasic',
          fill: false,
          hidden: true,
          tension: 0.3,
        },
        {
          label: '會員模組',
          data: [],
          key: 'shopMember',
          fill: false,
          hidden: true,
          tension: 0.3,
        },
        {
          label: '行銷模組',
          data: [],
          key: 'shopMarketing',
          fill: false,
          hidden: true,
          tension: 0.3,
        },
        {
          label: '預約模組',
          data: [],
          key: 'shopReservation',
          fill: false,
          hidden: true,
          tension: 0.3,
        },
        {
          label: '銷售模組',
          data: [],
          key: 'shopSale',
          fill: false,
          hidden: true,
          tension: 0.3,
        },
        {
          label: '堂票模組',
          data: [],
          key: 'shopClassTicket',
          fill: false,
          hidden: true,
          tension: 0.3,
        },
        {
          label: '電商模組',
          data: [],
          key: 'shopEcommerce',
          hidden: true,
          fill: false,
          tension: 0.3,
        },
        {
          label: '抽獎與使用卷模組',
          data: [],
          key: 'shopMemberGame',
          hidden: true,
          fill: false,
          tension: 0.3,
        },

        {
          label: '管理員登入紀錄',
          data: [],
          key: 'userActionLog',
          fill: false,
          hidden: false,
          tension: 0.3,
        },
      ],
    },
    chartOptions: {
      responsive: false,
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: '數量',
              fontSize: 16,
            },
            display: true,
            ticks: {
              beginAtZero: true, // minimum value will be 0.
            },
          },
        ],
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: '日期',
              fontSize: 16,
            },
            display: true,
            ticks: {
              beginAtZero: true, // minimum value will be 0.
            },
          },
        ],
      },
    },
  }),

  computed: {
    ...mapGetters(['shop', 'sidebar', 'shopName']),

    totalMemberCount () {
      return sum(this.chartData.datasets[1].data)
    },
    totalReservationCount () {
      return sum(this.chartData.datasets[0].data)
    },
    totalOrderCount () {
      return sum(this.chartData.datasets[2].data)
    },
    actionOptions () {
      return this.chartData.datasets.filter(dataset => dataset.key !== 'userActionLog')
    },
  },
  watch: {
    sidebar () {
      this.reloadChart()
    },
  },
  async mounted () {
    // this.dateRange[0] = dayjs().subtract(3, 'month').toDate()
    // this.dateRange[1] = dayjs().add(1, 'd').toDate()
    const docStyle = getComputedStyle(document.documentElement)
    // const setColor = (dataSet, label, colorNumber) => {
    //   const target = find(dataSet, { label })
    //   target.borderColor = docStyle.getPropertyValue(`--chart-${colorNumber}`)
    // }

    this.chartData.datasets.forEach((dataSet, index) => {
      dataSet.borderColor = docStyle.getPropertyValue(`--chart-${index}`)
    })

    // setColor(this.chartData.datasets, '預約新增', 1)
    // setColor(this.chartData.datasets, '會員新增', 2)
    // setColor(this.chartData.datasets, '訂單新增', 3)
    // setColor(this.reservationChartData.datasets, '預約', 1)
    // setColor(this.ecommerceOrderChartData.datasets, '訂單', 2)

    await this.fingShop()
    await this.changeTimeRange(true)
    const that = this
    window.onresize = () => that.reloadChart()
  },
  methods: {
    containerWidth () {
      return this.$refs['container-1']
        ? this.$refs['container-1'].clientWidth - 40
        : 400
    },
    reloadChart () {
      this.chartRendering = true
      setTimeout(() => {
        this.chartRendering = false
      }, 300)
    },
    async fingShop () {
      const [res, err] = await GetShopById(this.shop)
      if (err) return this.$message.error(err || err.msg)
      this.contractPlan = res.ContractPlan?.name

      // const shop = await GetShopById(this.shop)
      // this.contractPlan = shop.ContractPlan?.name
    },

    async getUserActionLog () {
      const [res, err] = await GetUserActionLog({
        shopId: this.shop,
        start: this.dateRange[0],
        end: dayjs(this.dateRange[1]).add(1, 'd').toDate(),
        splitType: 'week',
      })
      if (err) return this.$message.error(err)

      const dataset = find(this.chartData.datasets, { key: 'userActionLog' })
      this.chartData.labels = map(
        res,
        (item) => dayjs(item.start).format('YYYY-MM-DD'),
      )

      const countList = map(res, (item) => item.count)
      dataset.data = countList

      dataset.total = countList.reduce((a, b) => a + b, 0)
    },

    async getPageActionRecord (key, index) {
      const [res, err] = await GetPageActionRecord({
        shopId: this.shop,
        start: this.dateRange[0],
        end: dayjs(this.dateRange[1]).add(1, 'd').toDate(),
        splitType: 'week',
        key,
      })

      if (err) return this.$message.error(err)
      this.chartData.labels = map(
        res,
        (item) => dayjs(item.start).format('YYYY-MM-DD'),
      )
      const countList = map(res, (item) => item.count)
      this.chartData.datasets[index].data = countList
      this.chartData.datasets[index].total = countList.reduce((a, b) => a + b, 0)
    },

    async handleActionFilterChange (value) {
      this.chartData.datasets.forEach((dataSet) => {
        if (dataSet.key !== 'userActionLog') dataSet.hidden = true
      })
      // this.actionFilter = value
      value.forEach((item) => {
        const dataSet = find(this.chartData.datasets, { key: item })
        if (dataSet) dataSet.hidden = false
      })
      await this.getAllPageActionRecordData()
    },

    async getAllPageActionRecordData () {
      const waitList = []
      const that = this
      waitList.push(this.getUserActionLog())
      this.actionFilter.forEach(async (key, index) => {
        const targetIndex = findIndex(this.chartData.datasets, { key })
        waitList.push(that.getPageActionRecord(key, targetIndex))
      })
      this.chartRendering = true
      this.reload = true
      await Promise.all(waitList)
      this.reload = false
      this.chartRendering = false
    },

    async changeTimeRange (e) {
      if (!e) {
        console.log('in')
        this.chartData.datasets[0].data = []
        this.chartData.datasets[1].data = []
        return
      }
      this.reload = true
      await this.getAllPageActionRecordData()

      this.reload = false
    },
  },
}
</script>

<style>
.el-date-range-picker__editor.el-input.el-input--small {
  width: auto !important;
}
</style>

<style scoped lang="scss">
.time-picker-container {
  @apply mb-[24px];
}
.section-container {
  @apply bg-white h-[400px] flex justify-center items-center;
}

.title {
  @apply text-[24px] my-[30px];

  .sub {
    @apply text-[13px] text-[#666];
  }
}

.title-hint {
  @apply text-primary-100 mr-[5px];
}

.big-result-container {
  border-radius: 4px;
  @apply flex flex-wrap px-[20px] py-[10px] gap-[20px];
  margin-bottom: 20px;
  background: white;

  .result {
    margin: 2px 0;
    display: flex;
    gap: 3px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .result-number {
    @apply text-[24px] text-primary-100 font-bold;
  }
}

// .chart-wrapper {
//   @apply relative;

//   .y-unit {
//     @apply absolute top-0 left-[5px];
//   }

//   .x-unit {
//     @apply absolute right-[10px] bottom-[5px];
//   }
// }

</style>
